import React, { useEffect } from "react";
import "./App.css";
import { RedirectURL, SITEURL } from ".";

export default function Home() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = RedirectURL + window.location.pathname;
    }, 100);
  }, []);
  return (
    <div>
      <div>
        <h1 style={{ margin: "0%",fontSize:"x-large" }}>
          Ellora Expenses <br /> <span id="url">{SITEURL}{window.location.pathname}</span>
        </h1>
        <div class="container">
          <p class="text">Checking if the site connection is secure</p>
          <div class="spinner"></div>
          <p class="text">
            www.elloraexpenses.in needs to review the security of your
            connection before proceeding.
          </p>
        </div>
      </div>
    </div>
  );
}
